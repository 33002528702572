import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { VoyagesClient, VoyagesDto } from 'src/app/web-api-client';

@Injectable({
  providedIn: 'root'
})
export class MoveService implements OnDestroy {
  private moves: BehaviorSubject<VoyagesDto[]> = new BehaviorSubject([]);
  private data: VoyagesDto[];
  subscriptions: Subscription[] = [];

  constructor(private client: VoyagesClient) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getData(status: string): void {
    this.subscriptions.push(this.client.getVoyagesByStatus(status)
      .subscribe(result => {
        this.data = result;
        this.moves.next(result);
      }));
  }

  addData(data: VoyagesDto): void {
    this.data.unshift(data);
    this.moves.next(this.data);
  }

  get moves$(): Observable<VoyagesDto[]> {
    return this.moves.asObservable().pipe(shareReplay());
  }
}
